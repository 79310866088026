import React, { Component } from 'react';
import { Slider, Radio } from '../../../../../../../styleguide';
import _ from 'lodash';
const MAX_REM = 100;
const MAX_PERCENT = 100;

class SizeRem extends Component {
  updateSize = (type, value) => {
    const size = _.cloneDeep(this.props.value);
    size[type] = value;
    if (size.type === 'px') size.type = 'rem';
    if (type === 'type' && value === '%' && size['value'] > MAX_PERCENT) size['value'] = MAX_PERCENT;
    if (type === 'type' && (value === 'rem' || value === 'px') && size['value'] > MAX_REM) size['value'] = MAX_REM;
    this.props.updateSetting(this.props.settingKey, size);
  }

  render() {
    const { value, settingsTitle } = this.props;

    return (
      <div>
        <div className="set-flexalign">
          <h4>{settingsTitle}</h4>
          <div>
            <Radio.Group value={value.type === 'px' ? 'rem' : value.type} onChange={e => this.updateSize('type', e.target.value)}>
              <Radio.Button value="auto">auto</Radio.Button>
              <Radio.Button value="rem">rem</Radio.Button>
              <Radio.Button value="%">%</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className="option-inputs" style={{ padding: '0 10px', marginTop: value.type !== 'auto' ? '20px' : 0 }}>
         { value.type === 'rem' || value.type === 'px' ? (
            <Slider min={1} max={100} step={1}
            onChange={newVal => this.updateSize('value', newVal)}
            tipFormatter={label => `${label}rem`}
            value={
              typeof value.value === 'number' 
                ? (value.type === 'px' 
                    ? (value.value > 100 
                        ? 10 
                        : value.value
                      ) 
                    : value.value
                  ) 
                : 0
            }            
        />
          ) : null }
          { value.type === '%' ? (
            <Slider min={1} max={MAX_PERCENT} step={1}
              onChange={newVal => this.updateSize('value', newVal)}
              tipFormatter={label => `${label}%`}
              value={typeof value.value === 'number' ? value.value : 0}
            />
          ) : null }
        </div>
      </div>
    );
  }
}

export default SizeRem;
